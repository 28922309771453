<!-- h5首页 -->
<template>
  <div class="con">
    <router-view ref="childView" class="iframView" />
    <div class="menuDiv" style="display: none;">
      <mu-container id="footer" style="padding: 0px;">
        <mu-bottom-nav :value.sync="firstIndex" color="white" shift>
          <mu-bottom-nav-item value="/home" title="首页" to="/home" icon="home"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="/wysq" title="诉求" to="/wysq" icon="receipt_long"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="/zxkf" title="咨询" to="/zxkf" icon="question_answer"></mu-bottom-nav-item>
          <mu-bottom-nav-item value="/grzx" title="我的" to="/grzx" icon="person"></mu-bottom-nav-item>
        </mu-bottom-nav>
      </mu-container>
    </div>
    <div class="menuDialog">
      <mu-dialog width="360" transition="slide-bottom" fullscreen :open.sync="openFullscreen">
        <mu-appbar color="#276FFF" :title="fullscreenDiaolog">
          <mu-button slot="left" icon @click="closeFullscreenDialog">
            <!-- <mu-icon value="arrow_back_ios"></mu-icon> -->
            <i class="el-icon-arrow-left"></i>
          </mu-button>
        </mu-appbar>
        <div class="diaologIframe">
          <iframe class="iframeDiv" :src="diaologIframeUrl" frameborder="0"></iframe>
        </div>
      </mu-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      firstIndex: '/home',
      openFullscreen: false,
      fullscreenDiaolog: '',
      diaologIframeUrl: '' // 需要打开对话框的url地址
    }
  },
  components: {},
  computed: {},
  methods: {
    menuClick (name) {
      console.log(name)
    },
    openFullscreenDialog (titleName, url) {
      this.fullscreenDiaolog = titleName
      this.diaologIframeUrl = url
      this.openFullscreen = true
    },
    closeFullscreenDialog () {
      this.openFullscreen = false
      var url = window.location.href
      console.log()
      if (url.includes('sqcx') !== -1) {
        this.$refs.childView.search()
      }
    }
  },
  mounted () {
    this.firstIndex = this.$route.path
  }
}
</script>
<style lang="scss">
@import '../assets/css/index.scss';
</style>
